import axios from "api/axios";
import { toast } from "lib/helpers/utilities";

const TEAM_RUN_END_POINT = "team-run";

export const handlePullToPricerClickTeamRun = (id) => {
  if (!id) return;
  axios
    .get(`${TEAM_RUN_END_POINT}/pull-to-pricer?summary_id=${id}`)
    .then((res) => {
      toast(res?.data?.message, "success");
    });
};

export const getArchive = (summaryId) => {
  return axios.get(`${TEAM_RUN_END_POINT}/archive?summary_id=${summaryId}`);
};

export const getMultipleArchives = (summaryIds) => {
  return axios.get(`${TEAM_RUN_END_POINT}/archive`, {
    params: { summary_ids: summaryIds },
  });
};

export const getTeamRunProductById = async (productId) => {
  return await axios.get(`/team-run/rows?product_id=${productId}`);
};

// Update Bid And Ask API
export const removePremiumInBidOrAsk = (body) => {
  return axios.post(`${TEAM_RUN_END_POINT}/remove-premium`, { ...body });
};

export const finishUpdateBidOrAsk = (body) => {
  return axios.post(`${TEAM_RUN_END_POINT}/premium`, { ...body });
};

export const editAskOrBidTrader = (body) => {
  return axios.post(`${TEAM_RUN_END_POINT}/premium-trader`, { ...body });
};

// End Of Update Bid And Ask API Func

export const highLightAPI = async (summaryId) => {
  return await axios.get(
    `${TEAM_RUN_END_POINT}/highlight?summary_id=${summaryId}`
  );
};

export const makeActiveInActiveAPI = async (summaryIds, isActive) => {
  return await axios.get(`${TEAM_RUN_END_POINT}/active`, {
    params: { summary_ids: summaryIds, is_active: isActive },
  });
};

export const triggerSingleAPI = async (productId) => {
  return await axios.get(`/team-run?product_id=${productId}`);
};

export const triggerPushOptionToTeamRunAPI = async (body) => {
  return await axios.post(`/team-run/push-check`, { ...body });
};

export const updateTradeDeltaQuantity = async (body) => {
  return await axios.post(
    `team-run/trade/${body.delta ? "update-delta" : "update-delta-quantity"}`,
    { ...body }
  );
};

export const onUpdateOptionButton = async (body) => {
  return await axios.post(`/team-run/premium`, { ...body });
};

export const onTableClick = async (productId) => {
  return await axios.post(`team-run/notifications`, { product_id: productId });
};

export const getColors = async () => {
  return await axios.get("colors");
};

export const setColorForRow = async (queryParams) => {
  return await axios.get("team-run/font-color", {
    params: {
      ...queryParams,
    },
  });
};
