import { Col, Button, Row } from "antd";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

import { SHORTCUT_FILTER } from "lib/constant/VolSurface";
import { storeInLocalStorage } from "lib/helpers/Pricer/Table";
import React, { useEffect } from "react";

export default function Shortcuts({
  dark_Theme,
  volSurfaceFilterShortcuts,
  selectedFilters,
  setSelectedFilters,
  handleShortcutChange,
}) {
   const location = useLocation();
  const pathName = location.pathname;
  function onClickHandle(item) {
    setSelectedFilters((prev) => ({
      ...prev,
      product_id: null,
      contract_style: null,
      shortcutFilter: null,
      comparison_surface_id: null,
      live_surface_id : null
    }));
    storeInLocalStorage(SHORTCUT_FILTER, JSON.stringify(item.value));
    handleShortcutChange(item.value);
  }


  useEffect(()=>{
    if (pathName.includes("vol-surface-data")) {
       setSelectedFilters((prev) => ({
         ...prev,
         shortcutFilter:
           JSON.parse(localStorage.getItem(SHORTCUT_FILTER)) ||
           volSurfaceFilterShortcuts?.at(0)?.value,
         product_id: volSurfaceFilterShortcuts?.at(0)?.product_id.toString(),
         contract_style: volSurfaceFilterShortcuts?.at(0)?.contract_style,
       }));
    }
   
  },[pathName, setSelectedFilters, volSurfaceFilterShortcuts])

  return (
    <Col span={24}>
      <Row className="bias-btn">
        <Col span={3}>Shortcuts:</Col>
        <Col>
          <Row gutter={12}>
            {volSurfaceFilterShortcuts?.map((item) => (
              <Col key={item.label}>
                <Button
                  className={classNames({
                    "ant-btn-dark": dark_Theme,
                    "selected-button":
                      item.value === selectedFilters["shortcutFilter"],
                  })}
                  key={item.label}
                  onClick={() => onClickHandle(item)}
                >
                  {item.label}
                </Button>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
