/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Row, Select } from "antd";
import classnames from "classnames";
import { GlobalContext } from "lib/contexts/GlobalContext";
import { GRAPH_TABLE_FILTER_LABELS } from "lib/constant/GeneralActionButtons";
import { useLocation } from "react-router-dom";
import { setUserPreferencesForSSRM } from "lib/helpers/AuditTrail/generalHelper";
import { VolSurfaceContext } from "lib/contexts/VolSurfaceContext";
import "./FilterButtons.scss";
import { MarketAnalysisContext } from "lib/contexts/MarketAnalysisContext";
import useDebounce from "lib/hooks/useDebounce";
import { SHORTCUT_FILTER } from "lib/constant/VolSurface";
import { getDataFromLocalStorage } from "lib/helpers/GeneralFuncHelpers";
import RiskFree from "./RiskFree";
import SaveVolSurface from "./SaveVolSurface";
import SetLiveSurface from "./SetLiveSurface";
import SetComparisonSurface from "./SetComparisonSurface";
import WholeSurface from "./WholeSurface";
import Shortcuts from "./Shortcuts";
import SendEmail from "./SendEmail";
import DateAndExport from "./DateAndExport";
import SelectDate from "./SelectDate";
import DownloadButton from "./DownloadButton";

const FilterButton = ({
  selectedTab,
  setSelectedTab,
  handleRangePickerChange,
  start_date,
  end_date,
  initial_date,
  selectedFilters,
  setSelectedFilters,
  onBiasChange,
  batchUpdate,
  onUpdateGraphTitle,
  setLiveGraphId,
  liveGraphId,
  contractFilterLoading,
  setComparisonGraph,
  periodTypeFilterLoading,
  volSurfaceControl,
  setComparisonUpdated,
  comparisonGraph,
  isRiskFreeRevert,
  setIsRiskFreeRevert,
  initialRiskFree,
  surface_id,
  setVolSurfaceButtonClicked,
}) => {
  const location = useLocation();
  const pathName = location.pathname;
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const riskFreeRefValue = useRef(0);
  const { state } = useContext(VolSurfaceContext);
  const { MarketAnalysisState } = useContext(MarketAnalysisContext);
  const [riskFree, setRiskFree] = useState(initialRiskFree);
  const [shortcutFilter, setShortcutFilter] = useState(
    getDataFromLocalStorage(SHORTCUT_FILTER)
  );
  const debouncedRiskFree = useDebounce(riskFree, 1000);

  useEffect(() => {
    if (initialRiskFree) {
      setRiskFree(initialRiskFree);
    }
  }, [initialRiskFree]);

  const disabledGraphControl = useMemo(() => {
    let filters = { ...selectedFilters };
    delete filters.risk_free;
    if (filters && Object.keys(filters).length >= 4) {
      return false;
    }
    return true;
  }, [selectedFilters]);

  useEffect(() => {
    if (debouncedRiskFree !== undefined) {
      setSelectedFilters((prev) => ({ ...prev, risk_free: debouncedRiskFree }));
    }
  }, [debouncedRiskFree]);

  useEffect(() => {
    if (selectedFilters?.shortcutFilter) {
      setShortcutFilter(selectedFilters.shortcutFilter);
    }
  }, [selectedFilters]);
  useEffect(() => {
    if (shortcutFilter) {
      let selectedShortcutFilter = state.volSurfaceFilterShortcuts
        ? state.volSurfaceFilterShortcuts.filter(
            (short) => short.value === shortcutFilter
          )
        : null;
      if (selectedShortcutFilter && selectedShortcutFilter[0]) {
        selectedShortcutFilter = { ...selectedShortcutFilter[0] };
        delete selectedShortcutFilter.label;
        delete selectedShortcutFilter.value;
        let newFilters = {
          ...selectedFilters,
          shortcutFilter: shortcutFilter,
          ...selectedShortcutFilter,
          product_id: `${selectedShortcutFilter.product_id}`,
        };
        setSelectedFilters(newFilters);
      }
    } else if (shortcutFilter === null) {
      let newFilters = { ...selectedFilters, shortcutFilter: null };
      setSelectedFilters(newFilters);
    }
  }, [shortcutFilter]);

  useEffect(() => {
    if (isRiskFreeRevert && setIsRiskFreeRevert) {
      setRiskFree(riskFreeRefValue.current);
      setSelectedFilters((prev) => ({
        ...prev,
        risk_free: riskFreeRefValue.current,
      }));
      setIsRiskFreeRevert(false);
    } else {
      if (setIsRiskFreeRevert) {
        setIsRiskFreeRevert(false);
      }
      riskFreeRefValue.current = riskFree;
    }
  }, [isRiskFreeRevert]);

  useEffect(() => {
    const filterModel = {
      date_time: {
        filterType: "date",
        type: "inRange", // could also be 'equals', 'lessThan', 'greaterThan', etc.
        dateFrom: start_date,
        dateTo: end_date,
      },
    };
    let data = {
      table_name: pathName.includes("vol-surface")
        ? "vol_surface"
        : "market_analysis",
      filter_model: JSON.stringify(filterModel),
    };
    if (
      initial_date?.start_date !== start_date ||
      initial_date?.end_date !== end_date
    ) {
      setUserPreferencesForSSRM(data);
    }
  }, [start_date, end_date]);

  const showTradeButtons = useMemo(() => {
    let keys = location.pathname.includes("market-analysis")
      ? Object.keys(GRAPH_TABLE_FILTER_LABELS).reverse()
      : Object.keys(GRAPH_TABLE_FILTER_LABELS);
    return keys.map((item, index) => {
      return (
        <Button
          className={classnames({
            "ant-btn-dark": dark_Theme,
            "selected-button": GRAPH_TABLE_FILTER_LABELS[item] === selectedTab,
          })}
          key={GRAPH_TABLE_FILTER_LABELS[item] + index}
          onClick={() => setSelectedTab(GRAPH_TABLE_FILTER_LABELS[item])}
        >
          {GRAPH_TABLE_FILTER_LABELS[item]}
        </Button>
      );
    });
  }, [selectedTab]);

  const showFilterButtonsForGraphs = useMemo(() => {
    if (
      location.pathname.includes("market-analysis") ||
      selectedTab === GRAPH_TABLE_FILTER_LABELS.TABLE
    ) {
      return;
    }

    const disabledCondition =
      // disabledGraphControl ||
      !selectedFilters["product_id"] ||
      !selectedFilters["contract_style"] ||
      state?.volSurfaceBtnLoading;

    return (
      <Row gutter={[25, 25]} className="bias-btn">
        <RiskFree
          dark_Theme={dark_Theme}
          setRiskFree={setRiskFree}
          riskFree={riskFree}
          disabledCondition={disabledCondition}
        />

        <SaveVolSurface
          dark_Theme={dark_Theme}
          selectedFilters={selectedFilters}
          disabledGraphControl={disabledGraphControl}
          onUpdateGraphTitle={onUpdateGraphTitle}
        />

        {process.env.REACT_APP_NODE_ENV !== "production" ? (
          <SetLiveSurface
            dark_Theme={dark_Theme}
            liveGraphId={liveGraphId}
            setComparisonGraph={setComparisonGraph}
            setComparisonUpdated={setComparisonUpdated}
            setLiveGraphId={setLiveGraphId}
            userVolSurfaces={state?.userVolSurfaces}
            setSelectedFilters={setSelectedFilters}
            disabledCondition={disabledCondition}
          />
        ) : null}

        <SetComparisonSurface
          dark_Theme={dark_Theme}
          comparisonGraph={comparisonGraph}
          setComparisonGraph={setComparisonGraph}
          setComparisonUpdated={setComparisonUpdated}
          setSelectedFilters={setSelectedFilters}
          userVolSurfaces={state?.userVolSurfaces}
          disabledCondition={disabledCondition}
        />

        {/* Bias Controller */}
        <WholeSurface
          dark_Theme={dark_Theme}
          batchUpdate={batchUpdate}
          onBiasChange={onBiasChange}
          disabledCondition={disabledCondition}
        />
      </Row>
    );
  }, [
    location.pathname,
    selectedTab,
    selectedFilters,
    disabledGraphControl,
    state?.volSurfaceBtnLoading,
    state?.userVolSurfaces,
    riskFree,
    dark_Theme,
    liveGraphId,
    comparisonGraph,
    batchUpdate,
    onUpdateGraphTitle,
    setLiveGraphId,
    setComparisonUpdated,
    setSelectedFilters,
    setComparisonGraph,
    onBiasChange,
  ]);

  const filterButton = (filterState, parentKey, allowClear) => {
    const handleChange = (values) => {
      setSelectedFilters((prev) => ({
        ...prev,
        shortcutFilter: null,
        comparison_surface_id: null,
        live_surface_id: null,
      }));
      if (setComparisonGraph) {
        setComparisonGraph(undefined);
      }
      if (setLiveGraphId) {
        setLiveGraphId(undefined);
      }
      if (!values || values.length === 0) {
        const { [parentKey]: _, ...rest } = selectedFilters;
        setSelectedFilters({
          ...rest,
          shortcutFilter: null,
          product_id: rest.product_id ? rest.product_id : null,
          contract_style:
            rest.product_id && rest.contract_style ? rest.contract_style : null,
        });
      } else {
        setSelectedFilters((prev) => ({
          ...prev,
          contract_style: null,
          [parentKey]: values,
        }));
      }
    };

    return (
      <Select
        placeholder="Select filters"
        value={selectedFilters?.[parentKey] || []}
        onChange={(values) => {
          setShortcutFilter(null);
          handleChange(values);
        }}
        showSearch
        allowClear={allowClear}
        filterOption={(input, option) => {
          const inputSmallLetter = input.toLowerCase();
          const label = option.children.toLowerCase();
          return label.includes(inputSmallLetter);
        }}
        style={{ width: 180 }}
        disabled={state?.volSurfaceBtnLoading}
        className={dark_Theme ? "select-dark" : ""}
        popupClassName={dark_Theme ? "dropdown-dark" : ""}
        loading={
          parentKey === "contract_style"
            ? contractFilterLoading
            : parentKey === "period_id"
            ? periodTypeFilterLoading
            : false
        }
      >
        {filterState?.map((item) => (
          <Select.Option key={JSON.stringify(item)} value={item.value}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const showVolSurfaceFilters = useMemo(() => {
    if (
      location.pathname.includes("market-analysis") ||
      location.pathname.includes("vol-surface")
    )
      return null;
    return state?.volSurfaceFilters?.map((item, index) => {
      return (
        <div style={{ marginBottom: 10 }} key={JSON.stringify(item)}>
          <Row>
            <Col span={3}>
              <div>{item.label}</div>
            </Col>
            <Col>{filterButton(item.data, item.key, true)}</Col>
          </Row>
        </div>
      );
    });
  }, [
    state?.volSurfaceFilters,
    selectedFilters,
    contractFilterLoading,
    periodTypeFilterLoading,
    state?.volSurfaceBtnLoading,
    selectedTab,
  ]);

  const showMarketAnalysisFilters = useMemo(() => {
    if (selectedTab === "Table") return null;
    return MarketAnalysisState?.marketAnalysisFilters?.map((item, index) => {
      return (
        <div style={{ marginBottom: 10 }} key={JSON.stringify(item)}>
          <Row>
            <Col span={3}>
              <div>{item.label}</div>
            </Col>
            <Col>{filterButton(item.data, item.key, false)}</Col>
          </Row>
        </div>
      );
    });
  }, [
    MarketAnalysisState?.marketAnalysisFilters,
    selectedFilters,
    selectedTab,
  ]);

  const handleShortcutChange = (shortcutId) => {
    if (shortcutId) {
      setShortcutFilter(shortcutId);
      if (setComparisonGraph) {
        if (setLiveGraphId) {
          setLiveGraphId(undefined);
        }
        setComparisonGraph(undefined);
      }
    } else {
      setShortcutFilter(null);
      setSelectedFilters((prev) => ({
        ...prev,
        shortcutFilter: null,
      }));
    }
  };

  const volSurfaceDataPath = location.pathname.includes("vol-surface-data");
  const marketAnalysisPath = location.pathname.includes("market-analysis");

  return (
    <Row gutter={[0, 12]}>
      {!volSurfaceDataPath && (
        <SelectDate
          dark_Theme={dark_Theme}
          end_date={end_date}
          start_date={start_date}
          handleRangePickerChange={handleRangePickerChange}
          showTradeButtons={showTradeButtons}
        />
      )}

      {(volSurfaceDataPath ||
        (!marketAnalysisPath )) && (
        <Shortcuts
          dark_Theme={dark_Theme}
          handleShortcutChange={handleShortcutChange}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          volSurfaceFilterShortcuts={state?.volSurfaceFilterShortcuts}
        />
      )}

      {!(marketAnalysisPath || volSurfaceDataPath) && (
        <>
          <SendEmail dark_Theme={dark_Theme} />
          <Col span={24}>{showVolSurfaceFilters}</Col>
          <Col style={{ marginTop: 20 }} span={24}>
            {showFilterButtonsForGraphs}
          </Col>
        </>
      )}

      {marketAnalysisPath && (
        <>
          <Col span={24}>{showMarketAnalysisFilters}</Col>
          {selectedTab === "Table" && (
            <DateAndExport
              dark_Theme={dark_Theme}
              end_date={end_date}
              start_date={start_date}
            />
          )}
        </>
      )}
      {selectedFilters?.product_id &&
        selectedFilters?.contract_style &&
        volSurfaceDataPath && (
          <DownloadButton
            dark_Theme={dark_Theme}
            selectedFilters={selectedFilters}
            surface_id={surface_id}
          />
        )}
    </Row>
  );
};

export default FilterButton;
