import axios from 'api/axios';
import copy from "copy-to-clipboard";

// Constants
import { API_END_POINT_NAME } from "lib/constant/AuditTrail/AuditTrail";
import { FIELD_NAMES } from "lib/constant/Pricer/PricerTable";
import { toast } from "lib/helpers/utilities";

export const copyToClipboard = (message) => copy(message);;

export const handleCopyShortHand = (copyShortHand) =>{
  if(!copyShortHand) return;

  copyToClipboard(copyShortHand);
  toast("Row Copied", "success");
};
export const handleCopyShortHandLegs = (copyShortHand) =>{
  if(!copyShortHand) return;

  copyToClipboard(copyShortHand);
  toast("Row Copied", "success");
};

export const handleCopyTradeDetails = (tradeDetails) => {
  if(!tradeDetails) return;

  copyToClipboard(tradeDetails);
  toast("Row Copied", "success");
}

export const setUserPreferencesForSSRM=(body)=>{
  return axios.post('/user/grid-prefs',{...body})
}


export const handlePullToPricerClick =(selectedRows)=>{
  if(!selectedRows) return;

  axios.get(`/audit-trails/pull-to-pricer`, {
    params: {
      summary_ids:selectedRows,
    },
  })
  .then(res => toast(res?.data?.message, "success"))
};

export const cellStyle = (params) => {
  // if params.value is an object and color value exists return backgroundColor
  if(params?.value?.color){
    return {backgroundColor : params?.value?.color }
  }
}; 

export const defaultCellsStyle = {
  cellStyle: () => ({
    display: "flex",
    alignItems: "center",
    editable: false,
    resizable: true,
    filter: true,
  }),
};


export const returnSelectedIdForEditTradeDetails = (props, isPostReq = false) => {
  let summaryId, apiId, apiName;
  if(props.isPricer) {
    const isAggData = !props.rowSelectedInfo.data ? props.rowSelectedInfo?.node?.aggData : props.rowSelectedInfo?.data;
    summaryId = isAggData?.[FIELD_NAMES.SUMMARY_ID];
  }

  if(props.isTeamRun){
    summaryId = props.rowSelectedInfo['summary_id'];
  }

  if(props.isTeamRun){
    apiId = summaryId;
    apiName = API_END_POINT_NAME.TEAM_RUN
  }

  if(props.isPricer){
    apiId = summaryId;
    apiName = API_END_POINT_NAME.PRICER
  }

  //if TeamRun And Pricer Not Defined Then It's Gonna Be Audit Trail
  if(!props.isTeamRun && !props.isPricer){
    apiId = props.rowSelectedInfo.id;
    apiName = API_END_POINT_NAME.AUDIT_TRAIL
  }

  return !isPostReq ? `${apiName}/${apiId}` : apiName;
};
