import { FIELD_NAMES } from "lib/constant/Pricer/PricerTable";
import { GEEKS_USER } from "lib/constant/Profile/Profile";
import _ from "lodash";
import regression from "regression";
import { handleCopyShortHand } from "./AuditTrail/generalHelper";
import {
  getTenorValueFromString,
  getPeriodType,
} from "lib/helpers/Pricer/helperSortingFunc";

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

/**
 * Method used to remove
 *
 * @returns {boolean}
 */
export const clearUserInfo = () => {
  localStorage.removeItem(GEEKS_USER);

  return true;
};

export const localUserExist = () => {
  let userInfo = getDataFromLocalStorage(GEEKS_USER);
  return !!userInfo?.token;
};

export const getSvgPath_d = (e) => {

  const div = e.event?.target?.closest("div");
  const span = div && div?.querySelector("span");
  const svg = span && span?.querySelector("svg");
  const svgPath = svg && svg?.querySelector("path");
  const d = svgPath && svgPath?.getAttribute("d");


  const path = e.event.target?.getAttribute("d");

  let checkMenu =
    (path && path.startsWith("M904")) || (d && d.startsWith("M904"));

  return checkMenu;
};

export function restoreColumnStateFromLocalStorage(gridRef, state) {
  gridRef.current.columnApi.applyColumnState({
    state,
    applyOrder: true,
  });
}

export const PRICER_EXPANDED_ROWS = "pricer_expandedRows";

export const prepareDataForGroupedRows = (gridAPI, auditTrail = false) => {
  let idOfExpandedRows = {};
  gridAPI?.forEachNode((item) => {
    const nodeData = item.group ? item.aggData : item.data;
    if (
      item.expanded &&
      item[FIELD_NAMES.LEVEL] === 0 &&
      item[FIELD_NAMES.FIELD] === FIELD_NAMES.SUMMARY_REFERENCE_ID
    ) {
      if (auditTrail) {
        idOfExpandedRows = {
          ...idOfExpandedRows,
          [nodeData[FIELD_NAMES.ID]]: {
            [FIELD_NAMES.ID]: nodeData[FIELD_NAMES.ID],
          },
        };
      } else {
        idOfExpandedRows = {
          ...idOfExpandedRows,
          [nodeData[FIELD_NAMES.SUMMARY_ID]]: {
            [FIELD_NAMES.SUMMARY_ID]: nodeData[FIELD_NAMES.SUMMARY_ID],
            [FIELD_NAMES.LEGS]: [],
          },
        };
      }
    }

    if (
      item.expanded &&
      item[FIELD_NAMES.LEVEL] === 1 &&
      item[FIELD_NAMES.FIELD] === FIELD_NAMES.LEG_REFERENCE_ID
    ) {
      const legId = item?.aggData?.[FIELD_NAMES.LEG_ID];
      const summaryId = item?.parent?.aggData?.[FIELD_NAMES.SUMMARY_ID];
      idOfExpandedRows?.[summaryId]?.[FIELD_NAMES.LEGS]?.push(legId);
    }
  });

  return _.isEmpty(idOfExpandedRows) ? null : idOfExpandedRows;
};

export const expandedMatchedRows = (gridAPI, keyItem, auditTrail = false) => {
  gridAPI?.forEachNode((node) => {
    const nodeData = node.group ? node.aggData : node.data;
    if (auditTrail) {
      if (
        keyItem[FIELD_NAMES.ID] === nodeData[FIELD_NAMES.ID] &&
        nodeData[FIELD_NAMES.ID] === keyItem[FIELD_NAMES.ID] &&
        node[FIELD_NAMES.LEVEL] === 0
      ) {
        node.setExpanded(true);
      }
    } else {
      if (
        keyItem[FIELD_NAMES.SUMMARY_ID] === nodeData[FIELD_NAMES.SUMMARY_ID] &&
        nodeData[FIELD_NAMES.SUMMARY_ID] === keyItem[FIELD_NAMES.SUMMARY_ID] &&
        node[FIELD_NAMES.LEVEL] === 0
      ) {
        node.setExpanded(true);
      }

      if (keyItem[FIELD_NAMES.LEGS]?.length) {
        const findId = keyItem[FIELD_NAMES.LEGS].find(
          (item) => item === nodeData[FIELD_NAMES.LEG_ID]
        );
        if (
          findId &&
          nodeData[FIELD_NAMES.LEG_ID] === findId &&
          node[FIELD_NAMES.LEVEL] === 1
        ) {
          node.setExpanded(true);
        }
      }
    }
  });
};

export const isSummaryLevel = (params) => params.node.level === 0;

export function updateTeamRunData(title, dataToUpdate, currentData) {
  let tableData = [];
  currentData[title].api.forEachNode((node) => tableData.push(node.data));
  const newData = [...dataToUpdate];
  const updatedData = tableData.map(
    (item) => newData.find((o) => o.id === item.id) || item
  );
  return updatedData;
}

export const status200 = (res) => res.status === 200;

export const setDataToLocalStorage = (name, data) => {
  localStorage.setItem(name, JSON.stringify(data));
};

export const getDataFromLocalStorage = (name) => {
  const item = localStorage.getItem(name);
  if (item === null || item === "undefined") return null;
  return JSON.parse(item);
};

function generateEquationString(coefficients) {
  let equation = "";
  for (let i = 0; i < coefficients.length; i++) {
    if (i === 0) {
      equation += `${coefficients[i]}x^${coefficients.length - 1 - i} `;
    } else if (i === coefficients.length - 1) {
      equation += `+ ${coefficients[i]}`;
    } else {
      equation += `+ ${coefficients[i]}x^${coefficients.length - 1 - i} `;
    }
  }
  return "y = " + equation;
}

const plotRegressionModelPoints = (data, regressionModel) => {
  // compute high resolution data
  let xMin = Math.min(...data.map((point) => point[0])); // Assuming yourData is an array of arrays [[x1, y1], [x2, y2], ...]
  let xMax = Math.max(...data.map((point) => point[0]));
  let xValuesHighRes = Array.from(
    { length: 20 },
    (_, i) => xMin + (xMax - xMin) * (i / 19)
  );
  let highResData = xValuesHighRes.map((x) => [
    x,
    regressionModel.predict(x)[1],
  ]);
  return highResData;
};

export const updateGraphsDataWithRegression = (data) => {
  if (data) {
    let volSurfaceResponse = JSON.parse(JSON.stringify(data));
    let updatedData = [];
    volSurfaceResponse?.forEach((chartData) => {
      // let equation = "";
      let equationWithRoundedCoeff = "";
      let updatedSeries = chartData.series.map((seriesData) => {
        if (seriesData.is_regression === 1) {
          let regressionModelResult = regression.polynomial(
            seriesData.regression_data,
            {
              order: seriesData.polymonial_degree,
              precision: seriesData.polynomial_percision,
            }
          );

          const regressionLineDataPoints = plotRegressionModelPoints(
            seriesData.regression_data,
            regressionModelResult
          );
          seriesData.data = regressionLineDataPoints;

          let coefficients = regressionModelResult.equation.map((coeff) =>
            coeff.toFixed(3)
          );
          equationWithRoundedCoeff = generateEquationString(
            coefficients,
            seriesData.polymonial_degree
          );
          // equation = result.string;

          return seriesData;
        }
        return seriesData;
      });

      chartData.series = updatedSeries;
      updatedData.push(chartData);
    });
    return updatedData;
  }
  return [];
};

export const extractCopyShortHandForSummaryRows = (
  props,
  withVol = true,
  withLegs = false,
  detail_auditTrail = false
) => {
  const { api } = props;
  const selectedNodes = api.getSelectedNodes();
  const field = withLegs
    ? FIELD_NAMES.COPY_SHORTHAND_LEGS
    : withVol
    ? FIELD_NAMES.COPY_SHORTHAND
    : FIELD_NAMES.COPY_SHORTHAND_NO_VOLS;

  //filters rows with same summary id
  //get Selected Nodes only doesnt get grouped rows
  const levelZeroNodes = selectedNodes.map((item) => {
    if (item.level === 2) {
      return item.parent.parent;
    } else if (item.level === 1) {
      return item.parent;
    } else {
      return item;
    }
  });
  const selectedNodesWithoutDuplicates = levelZeroNodes.filter(
    (v, index, a) =>
      a.findIndex((v2) => {
        const v2Data = v2.aggData ? v2.aggData : v2.data;
        const v1Data = v.aggData ? v.aggData : v.data;
        if (detail_auditTrail) {
          return v2Data.audit_trail_id === v1Data.audit_trail_id;
        }
        return v2Data.summary_id === v1Data.summary_id;
      }) === index
  );
  const copyShorthands = selectedNodesWithoutDuplicates
    .map((item) => (item.aggData ? item.aggData[field] : item.data[field]))
    .join("\r\n");
  handleCopyShortHand(copyShorthands);
};

/// for selection
export const cellClickedListener = (e) => {
  const field = e.colDef.field;

  const checkMenu = getSvgPath_d(e);

  const itemObject = e.event.target.dataset;

  // const item =  itemObject.icon
  const item =
    Object.keys(itemObject).length > 0
      ? itemObject.icon
      : checkMenu
      ? "menu"
      : undefined;
  const isbulkMenuPressed = field === "actions" && item === "menu";
  const isSelected = e.node.isSelected();
  //deselects all nodes.
  const deselectAll = () => e?.api?.deselectAll();
  if (e.node.level > 0) {
    deselectAll();
    const parent = e.node.parent;
    let children = parent.allLeafChildren;
    if (parent !== "undefined" && parent.group && parent.level !== 0)
      children = parent.parent.allLeafChildren;
    children?.forEach((node) => node.setSelected(true));
    return;
  }
  /*
    this is for summary rows.
    if pressed on action menu just add the row to selected
    if not action menu remove selection on other rows and set it selected
  */
  if (!(isbulkMenuPressed && isSelected)) deselectAll();
  e.node.setSelected(true);
};

export const createQueryParamsForSSRM = (
  startRow,
  endRow,
  sortModel,
  groupKeys,
  filterModel,
  startDate,
  endDate
) => {
  let queryParams = {
    startRow: startRow,
    endRow: endRow,
  };

  if (sortModel && sortModel.length > 0) {
    queryParams.sortModel = sortModel;
  }

  if (groupKeys && groupKeys.length > 0) {
    queryParams.groupKeys = groupKeys;
  }

  if (filterModel && filterModel.length > 0) {
    queryParams.filterModel = filterModel;
  }

  return queryParams;
};

export const updateVolSurfaceDataForMainTable = (
  newData,
  oldData,
  isUnderlying = false,
  block_id
) => {
  let dataToUpdate = oldData.data?.filter((d) => d.block_id === block_id);

  let updatedBlockData = {
    ...dataToUpdate[0],
    main_control_table: isUnderlying
      ? dataToUpdate[0].main_control_table
      : newData.main_control_table_data,
    strike_control_tables: newData.strike_control_tables
      ? newData.strike_control_tables
      : dataToUpdate[0].main_control_table,
    graphs: dataToUpdate[0].graphs.map((graph) => {
      if (graph.id === newData.graph_id) {
        return {
          ...graph,
          backend_data: {
            ...graph.backend_data,
            second_control_graph: newData.second_control_graph,
          },
          graphs: graph.graphs.map((grp, index) => {
            return {
              ...grp,
              series: grp.series.map((ser) => {
                if (
                  ser.user_vol_smile_id &&
                  ser.user_vol_smile_id == newData.user_vol_smile_id
                ) {
                  return { ...ser, ...newData.graphs_data[index] };
                } else {
                  return ser;
                }
              }),
            };
          }),
        };
      }
      return graph;
    }),
  };
  let updatedData = {
    ...oldData,
    data: oldData.data.map((block) => {
      if (block.block_id === block_id) {
        return updatedBlockData;
      } else {
        return block;
      }
    }),
  };
  return updatedData;
};

export function updateGraphGridData(oldData, newData) {
  console.log("newData", newData);
  console.log("oldData", oldData);
  if (!oldData || !newData) {
    return oldData;
  }

  let updatedData;
  if (oldData.data) {
    oldData.data.map((block) => {
      if (block.block_id === newData.block_id) {
        console.log("block", block.graphs[0].graphs);
        const blockGraphs = block.graphs;
        const graphIndex = blockGraphs.findIndex(
          (graph) => graph.id === newData.graph_id
        );

        const newGraphData = block.graphs[graphIndex]?.graphs?.series?.map(
          (ser) => {
            if (
              ser.user_vol_smile_id &&
              ser.user_vol_smile_id === newData.user_vol_smile_id
            ) {
              return { ...ser, ...newData.data };
            } else {
              return ser;
            }
          }
        );
        console.log("newData", newGraphData);

        updatedData = [newData.data];
        return {
          ...block,
          graphs: newData.graphs,
        };
      } else {
        return block;
      }
    });
  } else {
    updatedData = [...oldData];
    const graphIndex = updatedData.findIndex(
      (graph) => graph.id === newData.graph_id
    );

    if (graphIndex === -1) {
      return oldData;
    }

    // Update simple fields
    Object.keys(newData).forEach((key) => {
      if (key !== "graphs" && updatedData[graphIndex]?.[key]) {
        if (key === "backend_data") {
          updatedData[graphIndex][key] = {
            ...updatedData[graphIndex][key],
            ...newData[key],
          };
        } else {
          updatedData[graphIndex][key] = newData[key];
        }
      }
    });

    // Update graph series
    updatedData[graphIndex].graphs = updatedData[graphIndex].graphs.map(
      (graph, index) => {
        const newGraphData = newData.graphs[index];

        if (!newGraphData) {
          return graph;
        }

        const updatedSeries = graph.series.map((seriesPoint) => {
          if (seriesPoint.user_vol_smile_id === newData.user_vol_smile_id) {
            return { ...seriesPoint, data: [...newGraphData.data] };
          }
          return seriesPoint;
        });

        return {
          ...graph,
          series: updatedSeries,
          subtitle: newGraphData.subtitle || graph.subtitle,
        };
      }
    );
    return updatedData;
  }
}

// i need to get rows ordered
// group the rows based on period type
// get the id of last row of each group
// add a border in the ag grid table based on the id of the last row of each group

export function processRowsForAddingBordersBetweenPeriodTypes(rowData) {
  // Clone the rowData to avoid modifying the original data
  if (rowData?.length === 0 || !rowData || rowData === undefined) {
    return [];
  }
  // the rule is different for Carbon
  const carbonRows = rowData.length > 0 && rowData[0].product === "Carbon";
  const groupingMethod = carbonRows ? getRowTenor : getRowPeriodType;
  const clonedData = _.cloneDeep(rowData);

  // Group the cloned rows based on "contract" and either "tenor" or "period type" based on the first row's product
  const rowsGroupedByGroupingMethod = _.groupBy(clonedData, (row) => {
    const contract = row.contract;
    const product = row.product;
    const groupingValue = groupingMethod(row);

    return `${product}_${contract}_${groupingValue}`;
  });

  // Iterate over each group and mark the last row in each group as the last row in the group
  Object.keys(rowsGroupedByGroupingMethod).forEach((groupingValue) => {
    const rowsInGroup = rowsGroupedByGroupingMethod[groupingValue];
    const lastRow = rowsInGroup[rowsInGroup.length - 1];
    lastRow.isLastInGroup = true;
  });

  return clonedData;
}

// adding borders for carbon according to the tenor
export function addingBordersForCarbon(params, productName) {
  let rowData = [];
  params.api.forEachNodeAfterFilterAndSort((node) => {
    const periodType = getRowTenor(node.data);
    node.data["period_type"] = periodType;
    rowData.push(node.data);
  });

  checkIfTheRowsPeriodAreSame(rowData);
}

// adding borders for options except carbon according to the period type
export function addingBordersForOptionsExceptCarbon(params) {
  const rowData = [];
  params.api.forEachNodeAfterFilterAndSort((node) => {
    const periodType = getRowPeriodType(node.data);
    node.data["period_type"] = periodType;
    rowData.push(node.data);
  });
  checkIfTheRowsPeriodAreSame(rowData);
}

function checkIfTheRowsPeriodAreSame(rowData) {
  rowData.forEach((itemDetail, index) => {
    const currentRow = rowData[index];
    if (rowData[index + 1]) {
      const nextItem = rowData[index + 1];
      if (currentRow?.period_type !== nextItem?.period_type) {
        itemDetail["different_period"] = true;
      } else {
        itemDetail["different_period"] = false;
      }
      if (nextItem?.is_active === currentRow?.is_active) {
        itemDetail["same_active"] = true;
      } else {
        itemDetail["same_active"] = false;
      }
    } else {
      const previousItem = rowData[index - 1];
      if (currentRow?.period_type !== previousItem?.period_type) {
        itemDetail["different_period"] = true;
      } else {
        itemDetail["different_period"] = false;
      }
      if (previousItem?.is_active === currentRow?.is_active) {
        itemDetail["same_active"] = true;
      } else {
        itemDetail["same_active"] = false;
      }
    }
  });
}

export function getRowPeriodType(node) {
  let formattedValue = node.tenor;
  //if the summary rows has more than one value then we need to sort by the first value
  formattedValue = formattedValue.split("/")[0].trim();
  // remove the Month from calendars and seasons (Mar)
  const periodStringA = getTenorValueFromString(formattedValue);
  // gets type if calendar, quarter, month, season
  const periodType = getPeriodType(periodStringA);

  return periodType;
}

export function getRowTenor(nodeData) {
  let formattedValue = nodeData.tenor.trim();
  const values = formattedValue.split("-");
  if (values.length > 0) {
    formattedValue = values[0].trim();
  }
  return formattedValue;
}



export function formatNumber(num) {
  // Convert the number to a string if it isn't one already
  if(!num) return num;
  let numStr = num.toString();

  // Check if the input string is a valid number
  if (isNaN(parseFloat(numStr)) || !isFinite(numStr)) {
      return numStr; // Return the original input if it's not a valid number
  }

  // Extract the sign, integer, and fractional parts
  const sign = numStr[0] === '-' ? '-' : '';
  const [integerPart, fractionalPart] = numStr.replace(/^-/, '').split('.');

  // Reverse the integer part to facilitate the insertion of commas
  let reversedInteger = integerPart.split('').reverse().join('');

  // Insert commas every three digits
  let withCommas = reversedInteger.match(/.{1,3}/g).join(',');

  // Reverse back to normal order and reconstruct the number
  let formattedInteger = withCommas.split('').reverse().join('');
  formattedInteger = sign + formattedInteger; // Re-add the sign

  // Concatenate the integer and fractional parts
  return fractionalPart ? `${formattedInteger}.${fractionalPart}` : formattedInteger;
}



function formatNumericValue(number) {
  const isInteger = Number.isInteger(number);

  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: isInteger ? 0 : 2,
  }).format(number);
}
