export const FULL_ROUTES_PATH = {
  HOME: {
    index: "/home",
  },
  SIGN_IN: {
    index: "/login",
  },
  RESET_PASSWORD: {
    ResetPassword: "/reset-password",
  },
  FORGET_PASSWORD: {
    forgetPassword: "/forget-password",
  },
  TRADER: {
    index: "/trader",
  },
  AUDIT_TRAIL: {
    index: "/audit-trail",
    auditDetails: "/audit-trail/:id",
  },
  PRICER: {
    newPricer: "/new-pricer",
    index: "/pricer",
  },
  TEAM_RUN: {
    index: "/team-run",
  },
  VOL_SURFACE: {
    index: "/vol-surface",
  },
  VOL_SURFACE_Data: {
    index: "/vol-surface-data",
  },
  MARKET_ANALYSIS: {
    index: "/market-analysis",
  },
  PROFILE: {
    index: "/profile",
  },
  ERROR: {
    500: "/internal-error",
    404: "/page-not-found",
    403: "/unexpected-error",
  },
};
