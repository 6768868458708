import axios from "api/axios";


const OPTION_ROWS = "option-rows";

export const fetchProductsExists = async () => {
  return await axios.get(OPTION_ROWS)
};

export const fetchGreeksLabelsAndValues = async () => {
  return await axios.get(`${OPTION_ROWS}/greeks`);
};

export const fetchOtherInfoData = async (summaryId) => {
  return await axios.get(`${OPTION_ROWS}/apo-info?summary_id=${ summaryId }`)
};
export const fetchTenors = async (summaryId,legId) => {
  return await axios.get(`/option-rows/tenors?summary_id=${summaryId}&leg_id=${legId}`);
};

export const updateOnEditCell = async (body) =>{
  return await axios.post(`${OPTION_ROWS}/update`, { ...body })
};

export const updateRowType = async (bodyParams) => {
  return await axios.get(`${OPTION_ROWS}/update-type`,{
    params:{...bodyParams}
  })
};

export const duplicateRow = async (summary_ids) => {
  return await axios.post(`${OPTION_ROWS}/bulk-duplicate`, {ids: summary_ids})
};

export const deleteRow = async (summary_ids) => {
  return await axios.delete(`${OPTION_ROWS}/bulk-delete`, { params : {ids : summary_ids} });
};

export const updateLive = async (body) =>{
  return await axios.post(`${OPTION_ROWS}/update`, { ...body })
};

export const updateLock = async (body) =>{
  return await axios.post(`${OPTION_ROWS}/update`, { ...body })
};

export const triggerPushOptionToPricer = async (body) => {
  return await axios.post(`/team-run/push-check`,{...body});
};
 
export const onPushOptionClicked = async (
  summaryId,
  tradingCompanies,
  same_team_run_option_id
) => {
  return await axios.post(`/team-run/push`, {
    summary_id: summaryId,
    trading_companies: tradingCompanies,
    same_team_run_option_id: same_team_run_option_id,
  });
};

export const triggerPushOptionToAuditTrailAPI =  async (summaryId,dateTime) => {
  return await axios.post("/option-rows/push-to-audit-trail", {
    summary_id: summaryId,
    date_time: dateTime,
  });
};
