import { Col, Button, Row } from "antd";
import { downloadVolSurfaceData } from "api/VolSurface/VolSurface";
import { useState } from "react";

export default function DownloadButton({ dark_Theme, selectedFilters , surface_id}) {
  const [isExportLoading, setIsExportLoading] = useState(false);

  const handleDownloadVolSurfaceData = () => {
    setIsExportLoading(true);
    let params = {
      product_id: selectedFilters?.product_id,
      contract_style: selectedFilters?.contract_style,
      surface_id: surface_id
      
    };
    if(!surface_id) delete params.surface_id
    downloadVolSurfaceData(params)
      .then((response) => {
        const fileUrl = response.data.data.url;
        const a = document.createElement("a");
        a.href = fileUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setIsExportLoading(false);
      })
      .catch((error) => {
        setIsExportLoading(false);
        console.log("Error while exporting the data>>", error);
      });
  };

  return (
    <>
      <Col span={24}>
        <Row size={"middle"} className={`${dark_Theme && "date-picker-dark"}`}>
          <Col span={3}>
            <span style={{ whiteSpace: "nowrap" }}>Download Surface:</span>
          </Col>
          <Col>
            <Button
              className={dark_Theme ? "ant-btn-dark" : "ant-btn-default"}
              onClick={handleDownloadVolSurfaceData}
              loading={isExportLoading}
            >
              Download
            </Button>
          </Col>
        </Row>
      </Col>
    </>
  );
}
