import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";

// List of initial state for all global data
export const initialState = {
  teamRunData       : {},
  isTeamRunLoading  : false,
  productIds        : null,
  selectedRows      : null,
  teamrunRefAgGrid  : null,
  tradePopup_data   : {
    isTradeItemClicked:false,
    data: {}
  },
  additionalPopup_data : {
    isOpen:false,
    data: {}
  },
  bidAskPopup_data   : {
    isOpen:false,
    isUpdateBidModalVisible:false,
    keyItem:"",
    selectedModal:"",
    triggerSingleAPIFunc:()=>{},
    data: {}
  },
};

// Actions
const SET_TEAM_RUN_DATA     = "SET_TEAM_RUN_DATA";
const IS_TEAM_RUN_LOADING   = "IS_TEAM_RUN_LOADING";
const SET_PRODUCT_IDS       = "SET_PRODUCT_IDS";
const SET_SELECTED_ROWS     = "SET_SELECTED_ROWS";
const SET_TRADE_POPUP_DATA  = "SET_TRADE_POPUP_DATA";
const SET_ADDITIONAL_POPUP_DATA = "SET_ADDITIONAL_POPUP_DATA";
const SET_TEAMRUN_REF_AGGRID= "SET_TEAMRUN_REF_AGGRID";
const SET_BID_ASK_POPUP_DATA= "SET_BID_ASK_POPUP_DATA";

// Shared Reducer For Global Context
const Reducer = (state, action) => {
  switch (action.type) {
    case IS_TEAM_RUN_LOADING:
      return {
        ...state,
        isTeamRunLoading: true,
      };
    case SET_TEAM_RUN_DATA:
      return {
        ...state,
        teamRunData: action.payload,
      };
    case SET_PRODUCT_IDS:
      return {
        ...state,
        productIds: action.payload,
      };
    case SET_TEAMRUN_REF_AGGRID:
      return {
        ...state,
        teamrunRefAgGrid: action.payload,
      };
    case SET_SELECTED_ROWS:
        return {
          ...state,
          selectedRows: action.payload,
        };
    case SET_TRADE_POPUP_DATA:
        return {
          ...state,
          tradePopup_data: action.payload,
        };
    case SET_ADDITIONAL_POPUP_DATA:
        return {
          ...state,
          additionalPopup_data: action.payload,
        };
    case SET_BID_ASK_POPUP_DATA:
        return {
          ...state,
          bidAskPopup_data: action.payload,
        };
    default:
      return state;
  }
};

// Global State Which its provide context for children
const TeamRunState = ({ children, defaultInitialState = {} }) => {
  const [state, dispatch] = useReducer(Reducer, { ...initialState, ...defaultInitialState });

  const setTeamRunData = useCallback((value) => {
    dispatch({ type: SET_TEAM_RUN_DATA, payload: value });
  }, []);

  const setIsTeamRunLoading = useCallback((value) => {
    dispatch({ type: IS_TEAM_RUN_LOADING, payload: value });
  }, []);

  const setProductIds = useCallback((value) => {
    dispatch({ type: SET_PRODUCT_IDS, payload: value });
  }, []);

  const setTeamrunRefAgGrid = useCallback((value) => {
    dispatch({ type: SET_TEAMRUN_REF_AGGRID, payload: value });
  }, []);

  const setSelectedRows = useCallback((value) => {
    dispatch({ type: SET_SELECTED_ROWS, payload: value });
  }, []);

  const setTradePopupData = useCallback((value) => {
    dispatch({ type: SET_TRADE_POPUP_DATA, payload: value });
  }, []);

  const setAdditionalPopupData = useCallback((value) => {
    dispatch({ type: SET_ADDITIONAL_POPUP_DATA, payload: value });
  }, []);

  const setBidAskPopupData = useCallback((value) => {
    dispatch({ type: SET_BID_ASK_POPUP_DATA, payload: value });
  }, []);

  const contextValue = useMemo(() => {
    return {
      state,
      setTeamRunData,
      setProductIds,
      setIsTeamRunLoading,
      setSelectedRows,
      setTradePopupData,
      setAdditionalPopupData,
      setBidAskPopupData,
      setTeamrunRefAgGrid
    };
  }, [state]);

  return (
    <TeamRunContext.Provider value={ contextValue }>
      { children }
    </TeamRunContext.Provider>
  );
};

// Create Global Context
export const TeamRunContext = createContext(initialState);

// Export Global State Context Component
export default TeamRunState;
export function useWebSocketContext() {
  const context = useContext(TeamRunContext);
  if (context === undefined) {
    throw new Error("useTestContext must be used within TestProvider");
  }
  return context;
}